body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: Menlo, Monaco, 'Courier New', monospace;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

/* Dark Theme */
body {
    background-color: #121212;
    color: #FFFFFF;
    --webkit-tap-highlight-color: #121212;
}

/* Google Matemasie Font */
.matemasie-regular {
    font-family: "Matemasie", sans-serif;
    font-weight: 400;
    font-style: normal;
}


/* Scrollbar styles */
@layer utilities {
    *, select {
        scrollbar-width: auto;
        scrollbar-color: #666666 #1E1E1E;
    }

    /* Webkit scrollbar styles for select dropdowns */
    select::-webkit-scrollbar {
        width: auto;
    }

    select::-webkit-scrollbar-track {
        background: #1E1E1E;
    }

    select::-webkit-scrollbar-thumb {
        background-color: #666666;
        border-radius: 8px;
        border: 2px solid #1E1E1E;
    }

    /* Hover state for better interactivity */
    select::-webkit-scrollbar-thumb:hover {
        background-color: #888888;
    }

    .flash-button {
        position: relative;
        overflow: hidden;
        isolation: isolate;
        -webkit-tap-highlight-color: transparent;
    }

    .flash-button::before,
    .flash-button::after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        height: 100%;
        background: rgba(255, 255, 255, 0.5);
        opacity: 0;
        border-radius: 8px;
        transform: translate(-50%, -50%);
    }

    .flash-button:active::before {
        animation: flash 0.2s ease-out;
    }

    .flash-button:active::after {
        animation: ripple 0.6s ease-out;
    }

    @keyframes flash {
        0% { opacity: 0; }
        50% { opacity: 0.25; }
        100% { opacity: 0; }
    }

    @keyframes ripple {
        0% {
            transform: translate(-50%, -50%) scale(0);
            opacity: 0.4;
        }
        100% {
            transform: translate(-50%, -50%) scale(2.5);
            opacity: 0;
        }
    }
}

/* Checkmark animation styles */
.checkmark-icon {
    opacity: 0;
    transform: scale(0);
    transition: all 0.2s ease-in-out;
}

input[type="checkbox"]:checked + label .checkmark-icon {
    opacity: 1;
    transform: scale(1);
}

/* Loading Ellipses styles */
@layer components {
    .animate-ellipsis {
        display: inline-flex;
        gap: 4px;
        margin-left: 8px;
    }

    /* Input and textarea base styles */
    .input-base {
        @apply bg-[#242424] border surface-border focus:border-[#90caf9] focus:outline-none;
    }

    /* App base colors */
    .app-bg {
        @apply bg-[#121212];
    }

    .surface-bg {
        @apply bg-[#1e1e1e];
    }

    .surface-border {
        @apply border border-[#323232];
    }

    .base-button {
        @apply bg-[#2a2a2a] hover:bg-[#323232] surface-border;
    }

    .base-surface {
        @apply bg-[#2a2a2a];
    }

    .primary-button {
        @apply bg-[#1976d2] border border-[#2196f3] hover:bg-[#2196f3] hover:border-[#42a5f5];
    }

    .alert-button {
        @apply bg-red-800 hover:bg-red-900 border border-red-700;
    }

    /*Sidebar styles*/
    .sidebar-container {
        @apply fixed top-0 left-0 h-full app-bg bg-opacity-80 backdrop-filter backdrop-blur-sm shadow-lg transition-all duration-300 border-r border-[#2a2a2a] flex flex-col z-50
    }

    .sidebar-collapsed-width {
        @apply w-16
    }

    .sidebar-expanded-width {
        @apply w-64
    }
    

    /* Loading Ellipses styles */

    .animate-ellipsis span {
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background-color: currentColor;
        display: inline-block;
    }

    .animate-ellipsis span:nth-child(1) {
        animation: 
            bounce 1s ease-in-out infinite,
            fade 2s ease-in-out infinite;
    }

    .animate-ellipsis span:nth-child(2) {
        animation: 
            bounce 1s ease-in-out 0.15s infinite,
            fade 2s ease-in-out 0.15s infinite;
    }

    .animate-ellipsis span:nth-child(3) {
        animation: 
            bounce 1s ease-in-out 0.3s infinite,
            fade 2s ease-in-out 0.3s infinite;
    }

    @keyframes bounce {
        0%, 100% {
            transform: translateY(0);
        }
        50% {
            transform: translateY(-10px);
        }
    }

    @keyframes fade {
        0%, 100% {
            opacity: 0;
        }
        50% {
            opacity: 1;
        }
    }
}

/* Slider styles */
:root {
    --slider-bg: #2a2a2a;
    --slider-track: #1976d2;
    --slider-text: #e0e0e0;
    --slider-text-hover: #ffffff;
    --slider-text-active: #ffffff;
}

.slider-container {
    position: relative;
    width: 300px;
    height: 40px;
    background: var(--slider-bg);
    border-radius: 8px;
    overflow: hidden;
    border: 1px solid #404040;
}

.slider-track {
    position: absolute;
    top: 4px;
    bottom: 4px;
    left: 4px;
    right: 4px;
    transition: transform 300ms ease-out;
    background: var(--slider-track);
    box-shadow: 0 2px 4px rgba(0,0,0,0.2);
    border-radius: 6px;
    width: calc(50% - 4px);
}

.slider-buttons {
    position: relative;
    z-index: 10;
    display: flex;
    height: 100%;
}

.slider-button {
    position: relative;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    font-size: 14px;
    font-weight: 500;
    transition: color 200ms;
    color: var(--slider-text);
    cursor: pointer;
    border: none;
    background: transparent;
}

.slider-button:hover:not(.active) {
    color: var(--slider-text-hover);
}

.slider-button.active {
    color: var(--slider-text-active);
}
